import React from "react"
import Sidebar from "../../components/aboutPage/sidebar"
import styled from "@emotion/styled"
import useAdministration from "../../hooks/use-administration"
import { Helmet } from "react-helmet"

const ContentWrapper = styled("div")`
  color: ${props => props.theme.colors.secondary};
  font-size: 0.9rem;
  line-height: 1.7;

  h3 {
    margin-bottom: 0.8rem;
    font-size: 1rem;
    font-weight: 600;
  }
  strong {
    font-weight: 500;
    color: black;
    font-size: 1rem;
  }

  p {
    padding-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;
    margin-bottom: 3rem;
  }
  li {
    padding: 0.5rem;
    margin: 1rem 0 1rem 0;
    border-bottom: 1px solid ${props => props.theme.colors.line};
    color: ${props => props.theme.colors.secondary};
    font-size: 0.9rem;
    ${props => props.theme.tablet} {
      padding: 0.5rem 0;
    }
  }
`

const About = props => {
  const data = useAdministration()
  return (
    <Sidebar>
      <Helmet>
        <title>{data.title}</title>
        <meta
          name="description"
          content="Фонд защиты прав граждан -  участников строительства ПК"
        />
      </Helmet>
      <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
      <ContentWrapper dangerouslySetInnerHTML={{ __html: data.content }} />
    </Sidebar>
  )
}

export default About
